body{
    background-color:#3B3B3B; 
    color: #fff;
}

header{
    background-color:#9D1F64;
}
.addbasket:hover {
  background: none repeat scroll 0 0 #A637D6;
}

#nav-principal ul li:hover > a, #nav-principal ul li.actif > a {
    color:white;
}

#nav-principal ul li a{
  color: white;
}

#footer{
    background:#9D1F64;
}
.nomprod, .wrap-description{
    color: #222;
}
.produits .produit_etiquette:after {
    border-color:rgb(157, 31, 100) rgba(65, 129, 217, 0) rgba(65, 129, 217, 0);
  
}
.produits .produit_etiquette {
    background:rgb(157, 31, 100);
}

.produits .produit_etiquette2 {
          background:rgb(157, 31, 100);
 
}
.produits .produit_etiquette2:after {
  border-color: rgba(0, 0, 0, 0)rgb(107, 33, 72) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
 }

.link, a, .side-tabs a{
    color:white;
}

.button, .addmsglo,.addbasket, .zoom {
    box-shadow: 0 4px #522A63;

    border-radius: 5px;
    background: #9D1F64;
    border-radius: 5px;
    border: transparent;
    color: white;
}


.button:active,.addmsglo:active,.addbasket:hover:active {
    box-shadow: 0 0  #522A63;
    top: 6px;
}

.adr {
    color: #FFFFFF;
}
.message_lo{
    border:white;
}

.addcommentaires {
    color: black;
}

.content>  h2,
.content>  .h2{
    background-color:  white;

}

.galerie{background-color: white;
}

.livre_dor > h2,
.livre_dor > .h2{
    background-color: white;
 
   
}

.bloc-adresse h2,
.bloc-adresse .h2{
    background-color: white;
}

.produits {
    background-color: white;
}


#fiche-produit .wrap-description {
    background: none repeat scroll 0 0 #FFFFFF;
}

.wrap-description > h3, .wrap-description > .h3, #fiche-produit .wrap-description>.row {
    background: none repeat scroll 0 0 white;
}  

/*
    * Fils d'ariane
*/

.arianne a{
  background: #B3B5B7;
   color: #444;
}
.link {
  color:black;
}

h1, .h1{
    color:white;
}

.prixprod{
    background-color:#522A63;
     color: white;
}
#nav-principal ul li > a:hover{
    border-bottom: 1px solid white;
    border-top: 1px solid white;
    color:white;
}
#nav-principal ul li ul li a {
  background:#9D1F64;
}

.wrap-description > h3, .wrap-description > .h3, #fiche-produit .wrap-description>.row {
  background: #9D1F64;
  color: white;
}

.bloc-adresse h2,
.bloc-adresse .h2 {
  background: white;
  color: black;
}

.message_lo .note {
   
    background: #9D1F64;
   
    color:white;

}
.message_lo .web {

    background: #9D1F64;
}

.message_lo .web a {
    color: white;
    
}




.produits select {
    background: none repeat scroll 0 0 #522A63;
    border: 1px solid #522A63;
    color: #FFFFFF;
 
}


.arianne a:hover:after, .arianne a.actif:after, .arianne a:hover:before, .arianne a.actif:before {
  background: none repeat scroll 0 0 #522A63;
}

.arianne a:hover, .arianne a.actif {
  background: none repeat scroll 0 0 #522A63;
  color: white;
}

.slogan{color:white;}
.pagination > .active > a, .pagination > .active > a:hover, .pagination > .active > a:focus, .pagination > .active > span, .pagination > .active > span:hover, .pagination > .active > span:focus {background-color: white; border-color: white;}.pagination > li > a, .pagination > li > span{color:white;}
.pagination.pull-right li.active a{color:#fff !important;}
.row.two-column-blog .apercu-article h2.titre-article, .row.two-column-blog .apercu-article .h2.titre-article, .single-article .titre-article h2, .single-article .titre-article .h2{color:#000 !important;}
.links_p tbody,.page,.clearfix,.contact_text,#contentgalerie p {
  color: white;
}

.select_style .fa {
  color: white;
}

.bx-pager-item a {
  background: none repeat scroll 0 0 white;

}
.bx-wrapper .bx-pager.bx-default-pager a:hover, .bx-wrapper .bx-pager.bx-default-pager a.active {
  background: none repeat scroll 0 0 #9D1F64;
}


.produit_etiquette, .produit_etiquette2, .produit_etiquette3{
    background:#9D1F64;
}
.produit_etiquette::after{
    border-color:#9D1F64 transparent transparent;
}

#footer p, #footer td, #footer a, #footer{
  color:#fff;
}

@media screen and (max-width:768px){#navigation li{background:#9D1F64}.menu_burger{color:#9D1F64}}

.button.button-secondary {
  background: orange;

  &:hover {
    background: #444;
  }
}

#flux-panier.template_flux_panier.template_flux_panier_1 .side-tabs ul li.step-done {
  background: #9D1F64;
  border-color: #9D1F64;

  a {
    color: #fff;
  }
}

#flux-panier.template_flux_panier.template_flux_panier_1 .side-tabs ul li.active {
  border-color: #9D1F64;
  color: #9D1F64;

  a, span {
    color: #9D1F64;
  }
}

#flux-panier.template_flux_panier.template_flux_panier_1 {
  color: #000;
}